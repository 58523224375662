import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import OfferModules from '../components/ModuleOffer/OfferModules';
import Seo from '../components/Seo/Seo';

const PageTemplate = (props) => {
  const post = props.data.post;
  const postData = {
    image: props.data.post.featuredImage.node,
    title: props.data.post.title,
    id: props.data.post.id,
  };

  useEffect(() => {
    document.body.classList.remove('mega-menu-open');
    document.body.classList.remove('form-enquiry-open');
    document.body.classList.remove('booking-open');
    document.body.classList.remove('menu-open');
  });
  return (
    <Layout>
      <Seo seo={post.seo}></Seo>
      <OfferModules postData={postData} modules={post.AcfOfferContent} />
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query OfferById($id: String!) {
    post: wpOffer(id: { eq: $id }) {
      id
      title
      featuredImage {
        node {
          altText
          mediaItemUrl
        }
      }
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...OfferModulesFragment
    }
  }
`;
