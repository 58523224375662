import React from 'react';
import ImageOpt from '../ImageOpt.js';

const Banner = ({ postData }) => {
  return (
    <section className="banner h-screen relative">
      <div className="banner-bg-img h-full bg-cover">
        <ImageOpt
          imgData={postData.image.mediaItemUrl}
          imgAlt="banner"
          imgLoad="eager"
          width={1440}
          height={856}
          imgClass="banner-bg-img h-full w-full object-cover"
        />
      </div>
      <div className="banner-btm-text relative z-[3]">
        <div className="banner-info text-center lg:pb-120 pb-50  fade-ani absolute bottom-0 w-full">
          <div className="flex justify-center lg:space-x-10 space-x-5">
            <h6 className="font-body tracking-018em text-14 text-white relative uppercase">
              Offer
            </h6>
          </div>
          <div className="banner-title mt-30">
            <h1 className="text-white tracking-004em up">{postData.title}</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
