import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React from 'react';
import ImageOpt from '../ImageOpt.js';

const ImageContent = ({ module, i, postData }) => {
  return (
    <>
      <section className="zigzag portrait lg:py-60 py-30 lg:mt-60 mt-30">
        <div className="container-fluid">
          <div className="flex flex-wrap items-center lg:space-y-0 space-y-10">
            <div
              className={`lg:w-6/12 w-full ${
                module.imagePosition === 'right' ? 'lg:order-2' : ''
              }`}
            >
              <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                <span>offers</span>
                <div className="title-black">
                  <h2>{module.heading}</h2>
                </div>
                <div className="content">{parse(module.description)}</div>
                <div className="btn-custom lg:mt-40 mt-20">
                  {module.button && (
                    <Link
                      to={module.button.url}
                      target={module.button.target}
                      className={`button button-tan mt-15 lg:mt-30 `}
                    >
                      {module.button.title}
                    </Link>
                  )}
                  {module.secondaryButton && (
                    <Link
                      to={module.secondaryButton.url}
                      target={module.secondaryButton.target}
                      className="text-link"
                    >
                      {module.secondaryButton.title}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              {module.image && (
                <ImageOpt
                  imgData={module?.image.mediaItemUrl}
                  imgAlt={module.image.altText}
                  imgLoad="lazy"
                  width={645}
                  height={648}
                  imgClass="object-cover w-full h-full"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageContent;
export const OfferImageContentFragment = graphql`
  fragment OfferImageContentFragment on WpOffer_Acfoffercontent_OfferContent_ImageContent {
    # content
    id
    description
    heading
    imageSize
    imagePosition
    button {
      url
      title
      target
    }
    secondaryButton {
      target
      title
      url
    }
    image {
      altText
      uri
      mediaItemUrl
    }
    fieldGroupName
  }
`;
