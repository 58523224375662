import { graphql, Link } from 'gatsby';
import parse from 'html-react-parser';
import React, { useState } from 'react';
import ImageOpt from '../ImageOpt.js';

const ImageWithTabContent = ({ module, i }) => {
  const [tabOption, setTabOption] = useState(module.tabs[0].tabHeading);
  return (
    <>
      <section className="zigzag portrait lg:py-60 py-30">
        <div className="container-fluid">
          <div className="flex flex-wrap lg:space-y-0 space-y-10">
            <div className="lg:w-6/12 w-full lg:order-2">
              <div className="zigzag-content xl:px-100 pr-0 lg:px-60 fade-ani">
                <span>offers</span>
                <div className="title-black">
                  <h2>Inclusions & Details</h2>
                </div>
                <div className="content">
                  <ul className="tabs flex space-x-5 mb-30">
                    {module.tabs.map((item, i) => {
                      return (
                        <li
                          onClick={() => {
                            setTabOption(item.tabHeading);
                          }}
                          className={`tab-link cursor-pointer text-black2 text-14 tracking-05em ${
                            tabOption === item.tabHeading ? 'tab-current' : ''
                          }`}
                          data-tab={item.tabHeading}
                        >
                          {item.tabHeading}
                        </li>
                      );
                    })}
                  </ul>
                  {module.tabs.map((item, i) => {
                    return (
                      <div className="tabbox">
                        <div
                          id={item.tabHeading}
                          className={`tab-content tab-current global-list ${
                            tabOption === item.tabHeading
                              ? 'tab-current'
                              : 'hidden'
                          }`}
                        >
                          {parse(item.tabDescription)}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="btn-custom lg:mt-40 mt-20">
                  <Link to="/traveler-enquiry/" className="button button-tan">
                    Make an Enquiry
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:w-6/12 w-full">
              {module.image && (
                <ImageOpt
                  imgData={module?.image.mediaItemUrl}
                  imgAlt={module.image.altText}
                  imgLoad="lazy"
                  width={645}
                  height={537}
                  imgClass="object-cover w-full h-full"
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ImageWithTabContent;
export const ImageWithTabContentFragmentOffer = graphql`
  fragment ImageWithTabContentFragmentOffer on WpOffer_Acfoffercontent_OfferContent_ImageWithTabContent {
    # content
    id
    heading
    imageSize
    imagePosition
    tabs {
      tabHeading
      tabDescription
    }
    image {
      altText
      uri
      mediaItemUrl
    }
    fieldGroupName
  }
`;
